import { create } from "@storybook/theming/create";

export default create({
	base: "dark",
	brandTitle: "CLINQ Mango | Component Library",
	brandUrl: "https://mango.clinq.com",
	brandImage:
		"https://firebasestorage.googleapis.com/v0/b/clinq-services.appspot.com/o/static-assets%2FbrandLogos%2Fmango.png?alt=media&token=dd77d5f9-d680-4c72-86e3-e8ece674fab9",
	brandTarget: "_self",
});
